// Fonts
$primaryFont: 'Open Sans', sans-serif;
$secondaryFont: 'Bree Serif', sans-serif;
$calendarFont: $primaryFont;

// Colors
$body: #EAEEF1;
$white: #FFFFFF;
$black: #1D1D1B;
$red: #EA0000;
$green: green;
$yellow: yellow;
$grey: #3B434C;
$lightgrey: #D7DEE5;

$defaultColorText: #62666B;
$defaultColorStart: #173C68;
$defaultColorEnd: #FF9100;

// General
$borderRadius: 3px;
$hoverOpacity: .85;

// Breakpoints
$tinyBreakpoint: 520px; // Mobile
$smallBreakpoint: 768px; // Ipad portrait
$mediumBreakpoint: 1024px; // iPad landscape
$largeBreakpoint: 1680px; // Desktop
