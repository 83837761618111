.input-wrapper {

  margin-bottom: 20px;

  @media only screen and (min-width: $mediumBreakpoint) {

    margin-bottom: 30px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    margin-bottom: 40px;
  }
}

.input-wrapper.input-error {

  color: #B32B26;

  .input, .textarea, .select {
    border-color: #B32B26;
    background-color: #f5e2e2;
  }

  .selectType:after {
    background-color: #f5e2e2;
  }
}

.form-label {

  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 10px;

  &-required {
    color: #B32B26;
    font-weight: 700;
  }

  @media only screen and (min-width: $mediumBreakpoint) {

    line-height: 20px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    font-size: 20px;
    margin-bottom: 20px;
  }
}

.input, .textarea, .select {

  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  background: $body;
  border: 1px solid $lightgrey;
  border-radius: 5px;
  color: $grey;
  font-weight: 300;

  @media only screen and (min-width: $mediumBreakpoint) {

    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    padding: 12px 17px;
    font-size: 20px;
    line-height: 27px;
  }
}

.textarea {

  height: 150px;
  resize: vertical;

  @media only screen and (min-width: $mediumBreakpoint) {

    height: 200px;
  }
}

.select {

  padding-right: 50px;

  @media only screen and (min-width: $mediumBreakpoint) {

    padding-right: 54px;
  }

  @media only screen and (min-width: $largeBreakpoint) {

    padding-right: 70px;
  }
}

.selectType {

  position: relative;

  &:after {

    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    background: $defaultColorStart url('/assets/images/chevron-down.svg') no-repeat center center / 12px;
    pointer-events:none;

    @media only screen and (min-width: $mediumBreakpoint) {

      width: 42px;
      height: 42px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      width: 53px;
      height: 53px;
    }
  }
}

.radio-option {

  margin-bottom: 10px;
  cursor: pointer;
  @extend %clearfix;

  & > input {

    display: none;
  }

  .radio {

    float: left;
    width: 20px;
    height: 20px;
    background-color: $body;
    border: 1px solid $lightgrey;
    border-radius: 30px;
    background-position: center center;
    transition: background ease-out .2s, border ease-out .2s;

    @media only screen and (min-width: $smallBreakpoint) {

      width: 30px;
      height: 30px;
    }

    &.checked {

      background: $defaultColorStart url('/assets/images/radio-check.svg') no-repeat center center / 8px;
      border: none;

      @media only screen and (min-width: $smallBreakpoint) {

        background-size: 12px;
      }
    }
  }

  & > label {

    float: left;
    width: calc(100% - 20px);
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    @media only screen and (min-width: $smallBreakpoint) {

      width: calc(100% - 30px);
      font-size: 16px;
      line-height: 30px;
      padding-left: 15px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      font-size: 20px;
    }
  }
}

.checkbox-option {

  margin-bottom: 10px;
  cursor: pointer;
  @extend %clearfix;

  & > input {

    display: none;
  }

  .checkbox {

    float: left;
    width: 20px;
    height: 20px;
    background-color: $body;
    border: 1px solid $lightgrey;
    background-position: center center;
    transition: background ease-out .2s, border ease-out .2s;

    @media only screen and (min-width: $smallBreakpoint) {

      width: 30px;
      height: 30px;
    }

    &.checked {

      background: $defaultColorStart url('/assets/images/checkbox-check.svg') no-repeat center center / 12px;
      border: none;

      @media only screen and (min-width: $smallBreakpoint) {

        background-size: 17px;
      }
    }
  }

  & > label {

    float: left;
    width: calc(100% - 20px);
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    @media only screen and (min-width: $smallBreakpoint) {

      width: calc(100% - 30px);
      font-size: 16px;
      line-height: 30px;
      padding-left: 15px;
    }

    @media only screen and (min-width: $largeBreakpoint) {

      font-size: 20px;
    }
  }
}
