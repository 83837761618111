.search {

  &__layout {

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(#000000, .5);
  }

  &__module {

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    padding: 20px;
    background: rgba($white, .9);

    @media only screen and (min-width: $mediumBreakpoint) {

      padding: 30px;
    }

    & > form {

      overflow: hidden;

      .input-box {

        float: left;
        width: calc(100% - 108px);

        @media only screen and (min-width: $smallBreakpoint) {

          width: calc(100% - 140px);
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          width: calc(100% - 180px);
        }

        .twitter-typeahead {

          width: 100%;
        }

        .tt-menu {

          position: inherit !important;
          top: inherit !important;
          left: inherit !important;
          z-index: inherit !important;

          .tt-selectable {

            padding: 5px 10px;
            opacity: .5;

            &:hover {

              //color: $defaultColorEnd;
              opacity: 1;
            }

            &.tt-cursor {

              //color: $defaultColorEnd;
              opacity: 1;
            }

            .tt-highlight {

              color: $defaultColorEnd;
            }
          }
        }

        input[type=search] {

          width: 100%;
          border: none;
          background: none;
          font-size: 20px;
          line-height: 32px;

          @media only screen and (min-width: $smallBreakpoint) {

            font-size: 30px;
            line-height: 50px;
            width: calc(100% - 140px);
          }

          @media only screen and (min-width: $mediumBreakpoint) {

            font-size: 45px;
            line-height: 82px;
            width: calc(100% - 180px);
          }

          &::-ms-clear {
            display: none;
          }

          &.tt-hint {

            opacity: .3 !important;
            background: none !important;
          }
        }
      }

      & > button[type=submit] {

        float: left;
        width: 34px;
        height: 34px;
        padding: 0;
        border-radius: 50%;
        background: $defaultColorEnd url('/assets/images/right-arrow.svg') no-repeat center center;
        background-size: 20px;
        border: 2px solid $defaultColorEnd;
        margin: 0 20px;
        cursor: pointer;

        @media only screen and (min-width: $smallBreakpoint) {

          width: 50px;
          height: 50px;
          margin: 0 20px;
          background-size: auto;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          width: 60px;
          height: 60px;
          margin: 12px 30px 0;
        }
      }
    }

    &__close {

      float: left;
      width: 34px;
      height: 34px;
      padding: 0;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid $defaultColorEnd;
      text-align: center;
      cursor: pointer;
      line-height: 30px;

      @media only screen and (min-width: $smallBreakpoint) {

        width: 50px;
        height: 50px;
        line-height: 46px;
      }

      @media only screen and (min-width: $mediumBreakpoint) {

        width: 60px;
        height: 60px;
        margin-top: 12px;
        line-height: 56px;
      }

      & > i {

        color: $defaultColorEnd;
        font-size: 14px;

        @media only screen and (min-width: $smallBreakpoint) {

          font-size: 20px;
        }
      }
    }
  }
}
