// Rewrite plugin classes
div.zabuto_calendar .table tr th,
div.zabuto_calendar .table tr td,
div.zabuto_calendar .table tr.calendar-month-header th,
div.zabuto_calendar .table tr.calendar-dow-header th,
div.zabuto_calendar .table tr.calendar-month-header th span,
div.zabuto_calendar div.calendar-month-navigation,
div.zabuto_calendar .badge-today, div.zabuto_calendar div.legend span.badge-today {

  background: none;
  padding: 0;
}

div.zabuto_calendar {

  font-family: $calendarFont;
  font-weight: 300;
  font-size: 14px;
  line-height: 34px;
}

div.zabuto_calendar .table {

  line-height: 24px;

  @media only screen and (min-width: $largeBreakpoint) {

    //line-height: 34px;
  }

  tr.calendar-month-header {

    line-height: 12px;

    th {

      font-size: 12px;
      padding-bottom: 10px;

      @media only screen and (min-width: $largeBreakpoint) {

        font-size: 16px;
        //padding-bottom: 15px;
      }

      &:nth-child(2) {

        text-transform: uppercase;
        font-weight: 300;
      }
    }
  }

  tr.calendar-dow-header th {

    color: $white;
    font-size: 12px;
    padding-bottom: 5px;

    @media only screen and (min-width: $largeBreakpoint) {

      font-size: 14px;
      padding-bottom: 15px;
    }
  }

  tr.calendar-dow {

    td {
      padding-bottom: 5px;
      font-size: 12px;

      @media only screen and (min-width: $largeBreakpoint) {

        font-size: 14px;
        padding-bottom: 15px;
      }

      .day {

        padding: 2px;

        .badge.badge-today {
          position: relative;
          font-size: 20px;
          font-weight: 600;
        }

        .badge.badge-event {
          padding: 0;
          text-align: center;
          border-radius: 50%;
          background-color: white;
          color: $defaultColorEnd;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }

        &.past {

          opacity: .5;
        }

        // Holiday
        &.event_holiday {

          position: relative;

          &::after {

            content: '';
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 28px;
            height: 28px;
            background-size: 28px 28px;
            background-image: url('/assets/images/event-holiday.svg');

            @media only screen and (min-width: $largeBreakpoint) {

              width: 34px;
              height: 34px;
              background-size: 34px 34px;
            }
          }

          &.today {

            &::after {

              background-image: url('/assets/images/event-holiday-fill.svg');
            }

            .badge.badge-today {

              color: $grey;
              font-size: inherit;
            }
          }
        }

        // Pedago 1
        &.event_pedago1 {

          position: relative;
          margin-top: 4px;
          margin-bottom: -4px;

          &::after {

            content: '';
            position: absolute;
            z-index: -1;
            top: 34%;
            left: 50%;
            width: 36px;
            height: 28px;
            transform: translate(-50%, -50%);
            background-size: 36px 28px;
            background-image: url('/assets/images/event-pedago1.svg');

            @media only screen and (min-width: $largeBreakpoint) {

              width: 42px;
              height: 34px;
              background-size: 42px 34px;
            }
          }

          &.today {

            &::after {

              background-image: url('/assets/images/event-pedago1-fill.svg');
            }

            .badge.badge-today {

              color: $grey;
              font-size: inherit;
            }
          }
        }

        &.event_custom {
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        // Pedago 2
        &.event_pedago2 {

          position: relative;
          margin-top: -4px;
          margin-bottom: 4px;

          &::after {

            content: '';
            position: absolute;
            z-index: -1;
            top: 66%;
            left: 50%;
            width: 36px;
            height: 28px;
            transform: translate(-50%, -50%);
            background-size: 36px 28px;
            background-image: url('/assets/images/event-pedago2.svg');

            @media only screen and (min-width: $largeBreakpoint) {

              width: 42px;
              height: 34px;
              background-size: 42px 34px;
            }
          }

          &.today {

            &::after {

              background-image: url('/assets/images/event-pedago2-fill.svg');
            }

            .badge.badge-today {

              color: $grey;
              font-size: inherit;
            }
          }
        }

        // Pedago 3
        &.event_pedago3 {

          position: relative;

          &::after {

            content: '';
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 28px;
            height: 28px;
            background-size: 28px 28px;
            background-image: url('/assets/images/event-pedago3.svg');

            @media only screen and (min-width: $largeBreakpoint) {

              width: 34px;
              height: 34px;
              background-size: 34px 34px;
            }
          }

          &.today {

            &::after {

              background-image: url('/assets/images/event-pedago3-fill.svg');
            }

            .badge.badge-today {

              color: $grey;
              font-size: inherit;
              text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
            }
          }
        }
      }
    }
  }
}

div.zabuto_calendar .legend {

  display: none;
}

.glyphicon {

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.glyphicon-chevron-left:before {
    content: "\e902";
  }
  &.glyphicon-chevron-right:before {
    content: "\e903";
  }
}
