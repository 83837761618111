.publicationsList {

  padding: 30px 0;

  @media only screen and (min-width: $smallBreakpoint) {

    padding: 30px 20px;
  }

  @media only screen and (min-width: $mediumBreakpoint) {

    padding: 50px;
  }

  &__title {

    position: relative;
    margin-bottom: 30px;
    padding: 0 20px;

    @media only screen and (min-width: $smallBreakpoint) {

      padding: 0;
      overflow: hidden;
      background: url('/assets/images/select-line.jpg') repeat-x center center;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      margin-bottom: 40px;
    }

    & > h3 {

      font-size: 20px;
      line-height: 27px;
      letter-spacing: .67px;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      font-weight: normal;
      background: $body;
      margin-bottom: 20px;

      @media only screen and (min-width: $smallBreakpoint) {

        float: left;
        max-width: calc(100% - 250px);
        font-size: 24px;
        line-height: 45px;
        letter-spacing: 1px;
        text-align: left;
        padding-right: 20px;
        margin-bottom: 0;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        font-size: 30px;
        line-height: 53px;
      }
    }

    & > select {

      background: $white;
      padding: 12px 52px 12px 20px;
      border-radius: $borderRadius;
      border: none;
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      width: 100%;

      @media only screen and (min-width: $smallBreakpoint) {

        float: right;
        width: auto;
        max-width: 250px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        padding: 15px 52px 15px 20px;
        line-height: 22px;
      }
    }

    .select-chevron {

      position: absolute;
      right: 40px;
      bottom: 17px;
      font-size: 8px;
      line-height: 8px;
      color: $defaultColorStart;
      pointer-events: none;

      @media only screen and (min-width: $smallBreakpoint) {

        bottom: inherit;
        right: 20px;
        top: 20px;
      }

      @media only screen and (min-width: $largeBreakpoint) {

        top: 24px;
      }
    }
  }

  &__articles {

    overflow: hidden;
    margin-bottom: 30px;
    padding: 0 20px;

    @media only screen and (min-width: $smallBreakpoint) {

      padding: 0;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      margin-bottom: 50px;
    }

    .flickity-viewport {

      overflow: visible;
    }

    .article {

      position: relative;
      float: left;
      width: 80%;
      margin-right: 20px;

      @media only screen and (min-width: $smallBreakpoint) {

        &--four {

          width: calc(25% - (30px / 4));
          margin-right: 10px;

          &:nth-child(4n+4) {

            margin-right: 0;
          }
        }

        &--three {

          width: calc(33.3334% - (20px / 3));
          margin-right: 10px;

          &:nth-child(3n+3) {

            margin-right: 0;
          }
        }

        &--two {

          width: calc(50% - (10px / 2));
          margin-right: 10px;

          &:nth-child(2n+2) {

            margin-right: 0;
          }
        }
      }

      &__image {

        display: block;
        height: 156px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        &:hover {
          opacity: $hoverOpacity;
        }

        @media only screen and (min-width: $mediumBreakpoint) {

          height: 175px;
        }

        @media only screen and (min-width: $largeBreakpoint) {

          height: 258px;
        }

        &--noimage {

          background: $defaultColorStart url('/assets/images/noimage-icon.svg') no-repeat center center / 75px;

          @media only screen and (min-width: $largeBreakpoint) {

            background-size: 100px;
          }
        }
      }

      &__block {

        position: relative;
        padding: 25px 20px 20px;
        background: $white;

        @media only screen and (min-width: $largeBreakpoint) {

          padding: 35px 30px 30px;
        }

        &__title {

          font-size: 16px;
          line-height: 18px;
          margin: 0 0 10px;
          font-weight: normal;
          height: 36px;
          overflow: hidden;

          &:hover {
            opacity: $hoverOpacity;
          }

          a {
            color: $defaultColorText;
          }

          @media only screen and (min-width: $largeBreakpoint) {

            font-size: 16px;
            line-height: 22px;
            height: 44px;
          }
        }

        &__text {

          font-size: 14px;
          line-height: 18px;
          height: 54px;
          overflow: hidden;
          margin-bottom: 10px;

          @media only screen and (min-width: $mediumBreakpoint) {

            margin-bottom: 15px;
          }

          @media only screen and (min-width: $largeBreakpoint) {

            font-size: 14px;
            line-height: 24px;
            height: 72px;
          }
        }

        &__link {

          font-size: 14px;
          line-height: 14px;

          &:hover {
            opacity: $hoverOpacity;
          }

          @media only screen and (min-width: $mediumBreakpoint) {

            font-size: 16px;
            line-height: 16px;
          }
        }

        &__plus {

          position: absolute;
          right: 10px;
          top: -18px;
          height: 35px;
          width: 35px;
          background: $defaultColorEnd url('/assets/images/plus-icon.svg') no-repeat center center / 15px;
          border-radius: 50%;

          @media only screen and (min-width: $mediumBreakpoint) {

            right: 20px;
          }

          @media only screen and (min-width: $largeBreakpoint) {

            right: 25px;
            top: -25px;
            height: 56px;
            width: 56px;
            background-size: 18px;
          }
        }
      }

      @media only screen and (min-width: $smallBreakpoint) {

        &--one {

          width: 100%;

          .article__image {

            height: 300px;

            @media only screen and (min-width: $smallBreakpoint) {

              height: 350px;
            }

            @media only screen and (min-width: $mediumBreakpoint) {

              height: 400px;
            }

            @media only screen and (min-width: $largeBreakpoint) {

              height: 525px;
            }
          }

          .article__block {

            max-width: 750px;
            margin: -90px auto 0;
          }
        }
      }
    }
  }

  &__more {

    padding: 0 20px;
    text-align: center;

    @media only screen and (min-width: $smallBreakpoint) {

      padding: 0;
    }
  }
}
