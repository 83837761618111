.gallery {

  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media only screen and (min-width: $smallBreakpoint) {

    margin-bottom: 0;
    gap: 20px;
  }

  @media only screen and (min-width: $mediumBreakpoint) {

    margin-bottom: 20px;
    gap: 30px;
  }

  .flickity-viewport {
    width: 100%;
  }

  a {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 320px;
    background-position: center center;
    background-size: cover;
    position: relative;

    @media only screen and (min-width: $smallBreakpoint) {

      width: calc(33.3334% - (40px / 3));
      height: 225px;

      &:nth-child(3n+3) {

        margin-right: 0;
      }

      &:hover .hover {

        opacity: 1;
      }
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      position: relative;
      width: calc(33.3334% - 20px);
    }

    @media only screen and (min-width: $largeBreakpoint) {

      height: 300px;
    }

    .hover {

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(#000000, .3);
      opacity: 0;
      transition: opacity ease-out .2s;

      &::after {

        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: $defaultColorEnd url('/assets/images/eye-icon.svg') no-repeat center center/ 30px;
      }
    }
  }

  &.with-comment {
    a.carousel-cell {
      height: auto;

      @media only screen and (min-width: $largeBreakpoint) {
        height: auto;
      }

      img {
        height: 320px;
        max-width: 100%;

        @media only screen and (min-width: $smallBreakpoint) {
          height: 225px;
        }

        @media only screen and (min-width: $largeBreakpoint) {
          height: 300px;
        }
      }

      p {
        font-size: 16px;
        color: $black;
      }
    }
  }
}

.flickity-prev-next-button {

  &.previous {

    width: 50px;
    height: 50px;
    padding: 0;
    background: $defaultColorEnd url('/assets/images/chevron-left.svg') no-repeat center right 10px / 12px;
    border-radius: 40px;
    left: -25px;
    transition: opacity ease-out .2s;

    &:disabled {

      opacity: .3;
    }

    svg {

      display: none;
    }
  }

  &.next {

    width: 50px;
    height: 50px;
    padding: 0;
    background: $defaultColorEnd url('/assets/images/chevron-right.svg') no-repeat center left 10px / 12px;
    border-radius: 40px;
    right: -25px;
    transition: opacity ease-out .2s;

    &:disabled {

      opacity: .3;
    }

    svg {

      display: none;
    }
  }
}
