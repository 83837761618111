.alert {

  background: $red;
  color: $white;
  padding: 20px 30px;

  @media only screen and (min-width: $smallBreakpoint){

    text-align: center;
  }

  &__message {

    padding-left: 63px;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .87px;
    background: url('/assets/images/warning-icon.svg') no-repeat left center / 40px;

    @media only screen and (min-width: $smallBreakpoint) {

      font-size: 18px;
      line-height: 24px;
      padding-left:40px;
    }

    @media only screen and (min-width: $mediumBreakpoint) {

      font-size: 20px;
      line-height: 34px;
      padding-left: 63px;
    }
  }
}
